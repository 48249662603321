import React from "react";
import Pictures from "./Pictures";
import Pdf from "./Pdf";
import Info from "./Info";
import View3D from "./View3D";

export default function Body(props) {
  let path = "";
  console.log(props.page);
  switch (props.page.device) {
    case "CV Board":
      path = "cvboard";
      break;

    case "Alarm Panel":
      path = "alarmpanel";
      break;

    case "Control Panel":
      path = "controlpanel";
      break;

    case "GPI Board":
      path = "gpiboard";
      break;

    case "GPO Board":
      path = "gpoboard";
      break;

    case "Lamp Board":
      path = "lampboard";
      break;

    case "MIDI Board":
      path = "midiboard";
      break;

    case "Serial Board":
      path = "serialboard";
      break;

    default:
      break;
  }

  const setPage = (device, page) => {
    props.setPage(device, page);
  };

  const makeBody = () => {
    let window = [];
    switch (props.page.page) {
      case "pictures":
        console.log("Yeah In Pictures");
        window = (
          <Pictures
            device={props.page.device}
            setPage={setPage}
            path={"/boardfiles/" + path}
          />
        );
        break;

      case "drawing":
        console.log("Yeah In Drawing");
        window = <Pdf yeah file={"/boardfiles/" + path + "/drawing.pdf"} />;
        break;

      case "bom":
        console.log("Yeah In BOM");
        window = <Pdf ok file={"/boardfiles/" + path + "/bom.pdf"} />;
        break;

      case "info":
        console.log("Yeah In INFO");
        window = <Info device={props.page.device} />;
        break;

      case "root":
        console.log("Yeah In ROOT");
        window = <Info device={props.page.device} />;
        break;

      case "3D":
        console.log("Yeah In 3D");
        window = <View3D device={props.page.device} />;
        break;

      default:
        break;
    }

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          overflowY: "auto",
          overflow: "hidden",
        }}
      >
        {window}
      </div>
    );
  };

  return <div style={{ height: "100%" }}>{makeBody()}</div>;
}
