import React from "react";

export default function Pdf(props) {
  console.log("PDF Render");
  return (
    <embed
      type="application/pdf"
      alt={props.file + "#view=FitH"}
      src={props.file}
      style={{ width: "100%", height: "100%" }}
    />
  );
}
