import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";

export default function Sidebar(props) {
  const boards = [
    {
      name: "Control Panel",
    },
    {
      name: "Alarm Panel",
    },
    {
      name: "GPI Board",
    },
    {
      name: "GPO Board",
    },
    {
      name: "CV Board",
    },
    {
      name: "Lamp Board",
    },
    {
      name: "MIDI Board",
    },
    {
      name: "Serial Board",
    },
  ];

  return (
    <Accordion
      id="aSide"
      defaultActiveKey="Project"
      style={{ whiteSpace: "nowrap", width: "160px" }}
    >
      <Accordion.Item
        eventKey={"Project"}
        style={{ cursor: "pointer" }}
        onClick={() => props.setPage("Project", "root")}
      >
        <Accordion.Header>Project</Accordion.Header>
        <Accordion.Body>Info</Accordion.Body>
      </Accordion.Item>
      {boards.map(brd => (
        <Accordion.Item
          key={brd.name}
          eventKey={brd.name}
          style={{ cursor: "pointer" }}
        >
          <Accordion.Header>{brd.name}</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => props.setPage(brd.name, "info")}
              >
                Info
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => props.setPage(brd.name, "pictures")}
              >
                Pictures
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => props.setPage(brd.name, "drawing")}
              >
                Drawing
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => props.setPage(brd.name, "bom")}
              >
                BOM
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => props.setPage(brd.name, "3D")}
              >
                3D Model
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
