import React, { Fragment } from "react";

export default function View3D(props) {
  console.log(props.device);
  const makeBody = () => {
    switch (props.device) {
      case "Control Panel":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968e89aebfb5e974911?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "Alarm Panel":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968a39ab7e15e0efe25?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "GPI Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968e666d29dd985e972?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "GPO Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd9686c6cd0a3437279b3?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "CV Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968f057b0cbd497fe75?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "Lamp Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968222b8c31aad9697d?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "MIDI Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd968393fac6c4a577a60?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      case "Serial Board":
        return (
          <Fragment>
            <iframe
              title="3dPCB"
              src="https://myhub.autodesk360.com/ue2ae7507/shares/public/SH56a43QTfd62c1cd96837b63f1c48357492?mode=embed"
              style={{
                minWidth: "100%",
                height: "100%",
                allowfullscreen: true,
                webkitallowfullscreen: true,
                mozallowfullscreen: true,
                frameborder: "0",
              }}
            ></iframe>
          </Fragment>
        );

      default:
        return;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        maxHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <div style={{ paddingLeft: "10px" }}>
        <h3>{props.device}</h3>
      </div>
      {makeBody()}
    </div>
  );
}
