import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function Pictures(props) {
  return (
    <div style={{ width: "100%", minHeight: "100%", maxHeight: "100%" }}>
      <pre style={{ paddingLeft: "10px" }}>
        Use scrollwheel to zoom | click and hold to drag
      </pre>
      <Tabs defaultActiveKey="Top" id="uncontrolled-tab-example">
        <Tab eventKey="Top" title="Top">
          <div style={{ height: "calc(100vh - 42px)" }}>
            <TransformWrapper
              wheel={{ step: 0.5 }}
              style={{ backgroundColor: "red" }}
            >
              <TransformComponent>
                <div classname="img-transform">
                  <div>
                    <img
                      alt="top"
                      src={props.path + "/top.png"}
                      className="img-style"
                    />
                  </div>
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
        </Tab>
        <Tab eventKey="Bottom" title="Bottom">
          <div style={{ height: "calc(100vh - 42px)" }}>
            <TransformWrapper
              wheel={{ step: 0.5 }}
              style={{ backgroundColor: "red" }}
            >
              <TransformComponent>
                <div className="img-transform">
                  <div>
                    <img
                      alt="bottom"
                      src={props.path + "/bottom.png"}
                      className="img-style"
                    />
                  </div>
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
