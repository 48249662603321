import React, { useState } from "react";
import Body from "./Body";
import Sidebar from "./Sidebar";

export default function Top() {
  const [state, setState] = useState({
    device: "Project",
    page: "root",
  });

  const showPage = (device, page) => {
    console.log("Device", device, "Page", page);
    setState({
      device: device,
      page: page,
    });
  };

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      style={{ width: "100%", height: "100%" }}
    >
      <tbody>
        <tr>
          <td
            style={{
              width: "1px",
              verticalAlign: "top",
              borderRight: "1px solid black",
            }}
          >
            <Sidebar setPage={showPage} />
          </td>
          <td style={{ verticalAlign: "top", height: "100%" }}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Body setPage={showPage} page={state} />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
