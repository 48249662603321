import React, { Fragment } from "react";

export default function Info(props) {
  let folder = "";
  console.log(props.device);
  const makeBody = () => {
    switch (props.device) {
      case "Control Panel":
        folder = "controlpanel";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 2</div>
              <div style={liStyle}>Through hole on top and bottom</div>
              <div style={liStyle}>Single SMD led on bottom</div>
              <div style={liStyle}>Wide PCB</div>
            </div>
          </Fragment>
        );

      case "Alarm Panel":
        folder = "alarmpanel";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 9</div>
              <div style={liStyle}>Through hole on top and bottom</div>
              <div style={liStyle}>Single SMD led on bottom</div>
            </div>
          </Fragment>
        );

      case "GPI Board":
        folder = "gpiboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 6</div>
            </div>
          </Fragment>
        );

      case "GPO Board":
        folder = "gpoboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 18</div>
            </div>
          </Fragment>
        );

      case "CV Board":
        folder = "cvboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 2</div>
            </div>
          </Fragment>
        );

      case "Lamp Board":
        folder = "lampboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 2</div>
            </div>
          </Fragment>
        );

      case "MIDI Board":
        folder = "midiboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 4</div>
            </div>
          </Fragment>
        );

      case "Serial Board":
        folder = "serialboard";
        return (
          <Fragment>
            <div style={bodyStyle}>
              <div style={liStyle}>Qty: 2</div>
            </div>
          </Fragment>
        );

      case "Project":
        console.log("In Root");
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "none",
            }}
          >
            <div style={{ ...bodyStyle, maxWidth: "900px", height: "100%" }}>
              <pre>
                This page should be viewed on a computer and not a mobile device
              </pre>
              <h4>Overview</h4>
              <ul>
                <li>45 PCBs total</li>
                <li>
                  You will see some black brackets on some of these boards. They
                  are for mounting to a DIN rail. You will <b>NOT</b> deal with
                  these brackets although they are listed in the BOMs and have
                  been shipped to you. We will attach them on site during the
                  install.
                </li>
                <li>
                  Alarm Panel and Control Panel both have a single 0603 LED on
                  bottom and through hole components on top and bottom
                </li>
                <li>All others are single side load</li>
                <li>
                  Pins on ethernet module should be trimmed with cutters after
                  soldering
                </li>
              </ul>
              <h4>First Pass (for testing)</h4>
              <div>
                One of each PCB completely assembled <b>EXCEPT</b> control panel
              </div>
              <div>
                Control panel assembled less the through hole pushbutton
                switches
              </div>
              <div style={{ padding: "5px" }} />
              <h4>Note</h4>
              <ul>
                <li>3D models take some time to load</li>
              </ul>
            </div>
            <div
              style={{
                borderTop: "1px solid lightGrey",
                textAlign: "center",
                padding: "5px",
                margin: "0px 10px",
              }}
            >
              &copy;WBM Tek | Marece Williams
            </div>
          </div>
        );

      default:
        return;
    }
  };

  const makeImage = () => {
    if (props.device !== "Project") {
      return (
        <div style={{ height: "100%" }}>
          <img
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={"/boardfiles/" + folder + "/render.png"}
            alt="render"
          />
        </div>
      );
    }
  };

  const makePnpLink = () => {
    if (props.device !== "Project") {
      return (
        <div style={{ paddingLeft: "10px" }}>
          <a
            href={"/boardfiles/" + folder + "/pnp.mnt"}
            download={folder + ".mnt"}
          >
            Download Pick And Place File
          </a>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        maxHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "0px 10px" }}>
        <h3>{props.device}</h3>
        <hr />
      </div>
      {makeBody()}
      {makePnpLink()}
      {makeImage()}
    </div>
  );
}

const liStyle = {
  display: "inline-block",
  marginRight: "10px",
  marginBottom: "10px",
  borderRadius: "6px",
  padding: "2px 6px",
  backgroundColor: "aliceBlue",
  userSelect: "none",
};

const bodyStyle = { padding: "0px 10px" };
